import { Pane } from "evergreen-ui";
import styles from './Components.module.scss';

const ProgressBar = {
    Line: () => {
        const view = <Pane className={styles.ProgressLine}>
            <Pane className={styles.ProgressLineStatus}>

            </Pane>
        </Pane>;
        return view;
    }
}

export default ProgressBar;