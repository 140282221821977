import axios from "axios";
import { Button, Pane, TextInputField, toaster } from "evergreen-ui";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Api } from "../Api";
import { useStore } from "../utils/Store";

const Signup = {
    Index: () => {
        const [cookies, setCookie] = useCookies(['_user']);
        const [store, setStore] = useStore();
        const [form, setForm] = useState({ name: '', email: '', password: '', confirm: '' });

        function handleForm(event) {
            const d = { ...form };
            d[event.target.name] = event.target.value;
            setForm(d)
        }

        function signupSubmit() {
            axios.post(Api.SIGNUP, { "name": form.name, "email": form.email, "password": form.password, "confirmPassword": form.confirm }).then(res => {
                if (res.data.success) {
                    setCookie('_user', res.data.data.id);
                    setStore({ ...store, balance: res.data.data.balance, user: res.data.data, authenticator: form.password });
                    toaster.success('Signup Success', {
                        duration: 1
                    });
                } else {
                    toaster.danger('Signup failed');
                }
            }, err => {
                toaster.danger('Signup failed');
            });
        }

        const view = <Pane className="columns col-gapless">
            <Pane className="colum col-6 col-md-12 ">
                <Pane style={{ padding: '50px' }}>
                    <TextInputField
                        label="Your full name" name="name" type="text" value={form.name} onChange={handleForm.bind(this)}
                    />
                    <TextInputField
                        label="Email" name="email" type="email" value={form.email} onChange={handleForm.bind(this)}
                    />
                    <TextInputField
                        label="Password" name="password"
                        type="password" value={form.password} onChange={handleForm.bind(this)}
                    />
                    <TextInputField
                        label="Confirm password" name="confirm"
                        type="password" value={form.confirm} onChange={handleForm.bind(this)}
                    />
                    <Pane display="flex" justifyContent="space-between">
                        <Button appearance="minimal">
                            Already have an account? Sign In
                        </Button>
                        <Button appearance="primary" onClick={signupSubmit}>
                            Sign Up
                        </Button>
                    </Pane>
                </Pane>
            </Pane>
            <Pane className="colum col-6 col-md-12 ">
                sdf
            </Pane>
        </Pane>
        return view;
    }
}


export default Signup;