import { Button, Pane, Paragraph, TextInputField, toaster } from "evergreen-ui";
import { Heading } from "evergreen-ui";
import ImageUploading from "react-images-uploading";
import { useState } from "react";

import FroalaEditorComponent from 'react-froala-wysiwyg';
import DatePicker from "react-horizontal-datepicker";
import axios from "axios";
import { Api } from "../Api";
import { useStore } from "../utils/Store";

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import styles from './User.module.scss';

const SellItem = {
    Index: () => {
        const [store, setStore] = useStore();
        const [images, setImages] = useState([]);
        const [form, setForm] = useState({ name: '', description: '', minBid: 0, endDate: new Date(), pin: '' });

        const maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + 30);

        const onChange = (imageList, addUpdateIndex) => {
            // data for submit
            console.log(imageList, addUpdateIndex);
            setImages(imageList);
        };

        const config = {
            placeholderText: 'Type your description',
            toolbarButtons: [['undo', 'redo', 'bold', 'italics', 'underline']],
        };

        function handleForm(event) {
            const f = { ...form };
            f[event.target.name] = event.target.value;
            setForm(f);
        }

        function updateDescription(e) {
            setForm({ ...form, description: e });
        }

        function selectedDay(e) {
            setForm({ ...form, endDate: e });
        }

        function submitCommodity() {
            const params = new FormData();

            if (!images.length) {
                return;
            }
            params.append('commodity', images[0].file)
            params.append('data', JSON.stringify({
                name: form.name,
                minBid: form.minBid,
                description: form.description,
                userId: store.user.id,
                endDate: form.endDate,
                pin: form.pin
            }));

            axios.post(Api.CREATE_COMMODITY, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                if (res.data.success) {
                    resetForm();
                    toaster.success('Item listed successfully', {
                        duration: 1
                    });
                } else {
                    toaster.danger('Failed');
                }
            });
        }

        function resetForm() {
            setForm({ name: '', description: '', minBid: 0, endDate: new Date() });
        }

        const view = <Pane className={styles.userContentWrapper}>
            <Heading size={800} className={styles.profilePanelAvatarLabel}>List your exclusive content</Heading>
            <Pane className="columns pt-2 mt-2">
                <Pane className="column col-6 col-md-12">
                    <Pane className="mb-2 pb-2">
                        <Heading size={400}>
                            Calculated initial valuation
                        </Heading>
                        <Pane className={styles.priceTag}>
                            <Heading size={800}>
                                $10
                            </Heading>
                        </Pane>
                        <Paragraph size={300} marginBottom={12}>
                            * This amount is calculated based on few criteria like old auctions, Instagram engagement etc.
                        </Paragraph>
                    </Pane>
                    <Pane className="mt-2 pt-2">
                        <TextInputField
                            required
                            label="Say about your content"
                            description="This will be displayed as the main title."
                            value={form.name}
                            name="name"
                            onChange={handleForm.bind(this)}
                            placeholder="Type here..."
                        />
                    </Pane>
                    <FroalaEditorComponent config={config} tag='textarea' onModelChange={updateDescription} />
                    <Pane className="pt-2 mt-2 mb-2 pb-2">
                        <Heading size={400} marginY={16}>
                            When do you want to end the auction?
                        </Heading>
                        <DatePicker
                            getSelectedDay={selectedDay}
                            selectDate={form.endDate}
                            labelFormat={"MMMM"}
                            endDate={60}
                            color={"#374e8c"}
                        />
                    </Pane>
                    <Pane className="pt-2 mt-2">
                        <TextInputField
                            required
                            label="4 digit PIN"
                            description="This pin is used to secure your commodity. You have to enter this pin again inorder to close the auction."
                            value={form.pin}
                            name="pin"
                            type="number"
                            maxLength="4"
                            onChange={handleForm.bind(this)}
                            placeholder=". . . ."
                        />
                    </Pane>
                    <Pane className={styles.buttonPanel}>
                        <Button marginRight={16} onClick={resetForm}>Reset</Button>
                        <Button appearance="primary" onClick={submitCommodity}>
                            Submit
                        </Button>
                    </Pane>
                </Pane>
                <Pane className="column col-6 col-md-12">
                    <Pane style={{ padding: '0 20px' }}>
                        <ImageUploading
                            value={images}
                            onChange={onChange}
                            dataURLKey="data_url"
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps
                            }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                    {!images.length ? <Pane
                                        style={isDragging ? { backgroundColor: "#5855d8" } : null}
                                        className={styles.uploadImgBox}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                        <Pane>Click or Drop here to add</Pane>
                                    </Pane> : ''}

                                    {imageList.map((image, index) => (
                                        <Pane key={index} className={styles.selectedImage}>
                                            <img src={image.data_url} alt="Commodity" />
                                            <Pane className={styles.buttonPanel}>
                                                <Button onClick={() => onImageUpdate(index)} marginRight={16} intent="none">
                                                    Update
                                                </Button>
                                                <Button onClick={() => onImageRemove(index)} intent="danger">
                                                    Remove
                                                </Button>
                                            </Pane>
                                        </Pane>
                                    ))}
                                </div>
                            )}
                        </ImageUploading>
                    </Pane>
                </Pane>
            </Pane>
        </Pane>;
        return view;
    }
};

export default SellItem;