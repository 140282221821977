import axios from "axios";
import { Avatar, Badge, Button, Heading, ListItem, Pane, TickCircleIcon, UnorderedList } from "evergreen-ui";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useRouteMatch, Switch, Route, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { Api } from "../Api";
import Product from "./Product";
import styles from './Seller.module.scss';

const Seller = {
    Index: () => {
        const routerBase = useRouteMatch();
        const [profile, setProfile] = useState();
        let { userId } = useParams();

        useEffect(() => {
            console.log(userId)
            axios.get(Api.SELLER_PROFILE + userId).then(res => {
                if (res.data.success) {
                    setProfile(res.data.data);
                }
            });
        }, [])

        return profile && profile.id ? <Pane className={styles.userHomeContainer + ' columns'}>
            <Pane className="column col-3 col-lg-4 col-md-12">
                <Seller.ProfilePanel profile={profile} />
            </Pane>
            <Pane className="column col-9 col-lg-8 col-md-12">
                <Switch>
                    <Route exact={true} path={routerBase.path}>
                        {/* <Seller.Home /> */}
                        <Product.Index />
                    </Route>
                    <Route exact={true} path={routerBase.path + '/item/:itemId'}>
                        <Product.Index />
                    </Route>
                </Switch>
            </Pane>
        </Pane> : <Pane>404</Pane>;
    },
    ProfilePanel: ({ profile }) => {
        let location = useLocation();

        const [activePage, setPage] = useState();

        useEffect(() => {
            if (location) {
                const path = location.pathname.split('/').reverse();
                setPage(path[0]);
            }
        }, [location]);


        const sideMenuList = [];

        const view =
            <motion.div
                animate={{ marginLeft: ['-100%', '0%'] }}
                transition={{ duration: 0.4 }}
                className={styles.profilePanel}>
                <Pane className="columns p-2">
                    <Pane className="column col-12  col-md-3 col-xs-12">
                        <Pane className="columns">
                            <Pane className="column col-auto col-mx-auto">
                                <Avatar
                                    src="https://png.pngtree.com/png-vector/20190130/ourlarge/pngtree-sexy-woman-wearing-white-dress-with-commercial-elements-paintedbeautyfemalecharacterillustration-png-image_617625.jpg"
                                    name={profile.name}
                                    size={100}
                                />
                            </Pane>
                        </Pane>
                    </Pane>
                    <Pane className="column col-12 col-md-auto px-2 col-xs-12">
                        <Pane className="columns">
                            <Pane className="column col-auto col-mx-auto">
                                <Heading size={800} className={styles.profilePanelAvatarLabel}>{profile.name}</Heading>
                                <UnorderedList>
                                    <ListItem icon={TickCircleIcon} iconColor="success" >
                                        @{profile.username}
                                    </ListItem>
                                </UnorderedList>
                            </Pane>
                        </Pane>
                    </Pane>
                    <Pane className="column col-12 col-md-auto col-xs-12 col-ml-auto">
                        <Pane className="columns">
                            <Pane className="column col-auto col-mx-auto">
                                <Button marginY="20px" intent="success">
                                    FOLLOW
                                </Button>
                            </Pane>
                        </Pane>
                    </Pane>
                    <Pane className="column col-12 col-md-auto col-xs-12 col-ml-auto">
                        <Pane className="columns">
                            <Pane className="column col-auto col-mx-auto">
                                <Heading className={styles.sellerRank} size={500}>Rank <span>
                                    #10
                                </span>
                                </Heading>
                            </Pane>
                        </Pane>
                    </Pane>
                    <Pane className={styles.profileSideMenu}>
                        {
                            sideMenuList.map(item => <Pane key={item.id} className={'profileSideMenuItem ' + (activePage === item.id ? ' active' : '')}>
                                <item.icon className="icon" />   {item.name}
                            </Pane>)
                        }
                    </Pane>
                </Pane>
            </motion.div >;
        return view;
    },
    Home: () => {
        const view = <Pane>
            sfggd
        </Pane>;

        return view;
    }
}

export default Seller;