import { Config } from "./config";

export const Api = {
    VALIDATE_TOKEN: Config.api + '/user/validate?token=',
    LOGIN: Config.api + '/user/login',
    DEPOSIT: Config.api + '/exchange/deposit',
    SIGNUP: Config.api + '/user/create',
    CREATE_COMMODITY: Config.api + '/commodity/create',
    COMMODITY: Config.api + '/commodity/item/',
    FEATURED: Config.api + '/commodity/featured',
    SELLER_PROFILE: Config.api + '/seller/profile/',
    PLACE_BID: Config.api + '/buyer/bid',
    SELLER_ACTIVE_COMMODITY: '/commodity/active',
    SELLER_INACTIVE_COMMODITY: '/commodity/sold',
    TRANSACTIONS: Config.api + '/user/transactions/'
}