
import styles from './App.module.scss';
import { Pane, Avatar, Heading, Button, CornerDialog, TextInputField, Tooltip, Position, HeatmapIcon, toaster, HomeIcon, Popover, StarIcon, TrendingUpIcon } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import socketIOClient from "socket.io-client";

import Home from './home/Home';
import axios from 'axios';
import { Config } from './config';
import { useStore } from './utils/Store';
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import User from './user/User';
import Seller from './seller/Seller';
import { useCookies } from 'react-cookie';
import { Api } from './Api';
import Signup from './user/Signup';


const App = {
  Index: () => {
    const [cookies, removeCookie] = useCookies(['_user']);
    const [store, setStore] = useStore();

    useEffect(() => {
      if (cookies && cookies._user) {
        console.log(cookies)
        axios.get(Api.VALIDATE_TOKEN + cookies._user, { abc: 'xxx' }).then(res => {
          if (res.data.success) {
            setStore({ ...store, balance: res.data.data.balance, user: res.data.data, authenticator: res.data.data.authenticator });

            axios.interceptors.request.use(req => {
              req.headers.authorization = cookies._user;
              return req;
            });

          } else {
            removeCookie('_user');
          }
        });
      } else {
        setStore({ ...store, balance: 0, user: null, authenticator: null });
      }
    }, [cookies]);

    const view =
      <Switch>
        <Route exact={true} path="/user/signup">
          <Signup.Index />
        </Route>
        <Route path="/profile/:userId">
          <Seller.Index />
        </Route>
        <Route path="/user">
          <User.Index />
        </Route>
        <Route path="/">
          <Home.Index />
        </Route>
      </Switch>;

    return <Router>
      <Pane className={styles.container}>
        <Pane className="columns app-nav-bar">
          <Pane className="column col-ml-auto col-auto col-sm-12">
            <App.SideBar />
          </Pane>
        </Pane>
        <Pane className={styles.contentWrapper}>
          {view}
        </Pane>
      </Pane>
    </Router>;

    // return <Pane className="columns">
    //   <Pane className="column col-ml-auto col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
    //     <Pane className="columns">
    //       <Pane className="column col-1">
    //         <App.SideBar />
    //       </Pane>
    //       <Pane className="column">
    //         <Pane className={styles.contentWrapper}>
    //           {/* {view} */}
    //         </Pane>
    //       </Pane>
    //     </Pane>
    //   </Pane>
    // </Pane>;
  },
  SideBar: () => {
    const [store, setStore] = useStore();
    const [accBalance, setAccBalance] = useState(0);

    useEffect(() => {
      if (store.user && store.user.id) {
        getUserUpdates(store.user.id);
        setAccBalance(store.balance);
      }
    }, [store.user]);

    function getUserUpdates(userId) {
      const socket = socketIOClient(Config.ws);
      socket.emit("user_balance", { id: userId });

      socket.on("balance_changes", data => {
        if (accBalance !== data) {
          toaster.success('Your current balance is $' + data, {
            duration: 1
          });
          setAccBalance(data);
          setStore({ ...store, balance: data });
        }
      });
    }

    const view = <Pane className={styles.sideBar}>
      <Pane className={styles.brandLogo}>
        <Link to="/">
          <img alt="Amuse Bouche" src="https://avatars3.githubusercontent.com/u/22205159?s=280&v=4" />
        </Link>
      </Pane>
      <Pane className={styles.sidebarMenu + ' hide-sm'}>
        <Link to="/">
          <Tooltip content="Home" position={Position.RIGHT}>
            <Pane className={styles.sideBarMenuItem + ' ' + styles.sideBarMenuItemActive}>
              <HomeIcon size={20} />
            </Pane>
          </Tooltip>
        </Link>
        <Tooltip content="Today's trends" position={Position.RIGHT}>
          <Pane className={styles.sideBarMenuItem}>
            <TrendingUpIcon size={20} />
          </Pane>
        </Tooltip>
        <Tooltip content="High rated Sellers" position={Position.RIGHT}>
          <Pane className={styles.sideBarMenuItem}>
            <StarIcon size={20} />
          </Pane>
        </Tooltip>
        <Pane className={styles.sideBarUserData}>

          <App.Wallet balance={accBalance} />

          <Popover
            content={({ close }) =>
              <Pane
                width="350px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                paddingX="20px"
                paddingY="40px"
              >
                {store.user && store.user.id ? <App.UserInfo close={close} /> : <User.Login />}

              </Pane>
            }
            position={Position.RIGHT}
          >
            {store.user && store.user.id ? <Pane className={styles.userInfo}>
              <Avatar src="https://media.istockphoto.com/vectors/default-avatar-man-to-social-user-vector-id860264876?k=6&m=860264876&s=170667a&w=0&h=2YApZVjoA2w1PBJonLA-PUfgbIHltDXqBZWlJI2Zo7M="
                name={store.user.name}
                size={50}
              />
              <Heading size={300}>{store.user.name}</Heading>
            </Pane> : <Button width="100%">Sign In</Button>}
          </Popover>

          {store.user && store.user.id ? '' : <Link to="/user/signup"><Button marginTop={10} appearance="primary" intent="success" width="100%">New User</Button></Link>}
        </Pane>

      </Pane>
    </Pane >
    return view;
  },
  UserInfo: ({ close }) => {
    const [cookies, removeCookie] = useCookies(['_user']);
    const [store, setStore] = useStore();

    function logout() {
      removeCookie('_user');
      setStore({ ...store, balance: 0, user: null, authenticator: null });
    }

    const view = <Pane display="flex" justifyContent="space-between">
      <Button appearance="minimal" onClick={logout}>
        Logout
      </Button>
      <Link to="/user/dashboard">
        <Button appearance="primary" onClick={close}>
          My Profile
        </Button>
      </Link>
    </Pane>;
    return view;
  },
  Wallet: ({ balance }) => {
    const [store] = useStore();
    const [userData, setUserData] = useState({});
    const [depositForm, setDepositForm] = useState({ amount: 10 });
    const [formBusy, setFormBusy] = useState(false);

    useEffect(() => {
      if (store.user) {
        setUserData(store.user);
      }
    }, [store.user]);

    function handleDepositForm(event) {
      const d = { ...depositForm };
      d[event.target.name] = event.target.value;
      setDepositForm(d);
    }

    function depositSubmit() {
      setFormBusy(true);
      axios.post(Api.DEPOSIT, {
        "account": userData.id,
        "amount": depositForm.amount,
        "pin": store.authenticator
      }).then(res => {
        if (res.data.success) {
          toaster.success('Recharge success');
        } else {
          toaster.danger('Recharge failed');
        }
        setFormBusy(false);
      }, err => {
        toaster.danger('Recharge failed');
        setFormBusy(false);
      });
    }

    const popRecharge = ({ close }) => <Pane
      width="350px"
      paddingX="20px"
      paddingY="40px"
    >
      <Heading marginBottom={20}>Recharge your wallet</Heading>
      <TextInputField
        label="Enter amount" name="amount" type="number" value={depositForm.amount} onChange={handleDepositForm.bind(this)}
      />

      <Pane className="btn-set">
        <Button onClick={close}>Cancel</Button>
        <Button appearance="primary" isLoading={formBusy} onClick={depositSubmit}>Pay</Button>
      </Pane>
    </Pane>;

    const view = <Pane>
      <Popover
        width="350px"
        content={userData && userData.id ? popRecharge : <User.Login />}
        position={Position.RIGHT}
        shouldCloseOnExternalClick={!(userData && userData.id)}
        paddingX="20px"
        paddingY="40px"
      >
        <Pane className={styles.userWallet}>
          <Heading size={300}>Wallet</Heading>
          <Heading size={600}>${balance}</Heading>
        </Pane>
      </Popover>
    </Pane>;
    return view;
  }
}

function Appf() {
  const [store, setStore] = useStore();
  const [showLoginDialog, setLoginDialog] = useState(true);
  const [showSignupDialog, setSignupDialog] = useState(false);
  const [accBalance, setAccBalance] = useState(0);
  const [signupForm, setSignupForm] = useState({ name: '', email: '', password: '', confirm: '' });



  const [userData, setUserData] = useState({});

  function getUserUpdates(userId, pin) {
    const socket = socketIOClient(Config.ws);
    socket.emit("user_balance", { id: userId, pin });

    socket.on("balance_changes", data => {
      setAccBalance(data);
      if (accBalance !== data) {
        toaster.success('Your current balance is $' + data, {
          duration: 1
        });
      }
    });
  }



  function handleSignupForm(event) {
    const d = { ...signupForm };
    d[event.target.name] = event.target.value;
    setSignupForm(d)
  }

  function signupSubmit() {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-Auth-Token': '97e0d315477f435489cf04904c9d0e6co',
    };

    axios.post(Api.SIGNUP, {
      "name": signupForm.name,
      "email": signupForm.email, "password": signupForm.password, "confirmPassword": signupForm.confirm
    }, headers).then(res => {
      if (res.data.success) {
        setLoginDialog(false);
        setUserData(res.data.data);
        setAccBalance(res.data.data.balance);

        setStore({ ...store, balance: res.data.data.balance, user: res.data.data, authenticator: signupForm.password });
        getUserUpdates(res.data.data.id, signupForm.password);
        toaster.success('Welcome to Amuse Bouche!', {
          duration: 1
        });
      } else {
        toaster.danger('Signup failed');
      }
    }, err => {
      toaster.danger('Signup failed');
    });
  }



  return (
    <Pane>
      <Pane display="flex" alignItems="center" padding={16} paddingLeft={50} background="tint2" borderRadius={3}>
        <Pane flex={1} alignItems="center" display="flex">
          <HeatmapIcon marginRight={20} size={30} />
          <Heading size={600}>Amuse Bouche</Heading>
        </Pane>
        <Pane display="flex" alignItems="center" paddingRight={50}>
          {/* Below you can see the marginRight property on a Button. */}
          <Tooltip content="Add deposit" position={Position.BOTTOM}>
            {/* <Button onClick={() => setAddDepositDialog(true)} intent="success" marginY={8} marginRight={12} iconBefore={PlusIcon}>
              <Heading size={600}>${accBalance}</Heading>
            </Button> */}
          </Tooltip>
          {userData && userData.id ? <Avatar name={userData.name} size={40} /> : ''}
        </Pane>
      </Pane>

      <Pane>
        <Home.Index />
      </Pane>

      <CornerDialog
        title={showSignupDialog ? 'Create new account' : 'Login'}
        hasFooter={false}
        isShown={showLoginDialog}
        onCloseComplete={() => setLoginDialog(false)}
      >
        {showSignupDialog ?
          <Pane>
            <TextInputField
              label="Your name" name="name" value={signupForm.name} onChange={handleSignupForm.bind(this)}
            />
            <TextInputField
              label="Your email" name="email" type="email" value={signupForm.email} onChange={handleSignupForm.bind(this)}
            />
            <TextInputField
              label="Enter a password"
              placeholder="...." name="password"
              type="password" value={signupForm.password} onChange={handleSignupForm.bind(this)}
            />
            <TextInputField
              label="Retype your password"
              placeholder="...." name="confirm"
              type="password" value={signupForm.confirm} onChange={handleSignupForm.bind(this)}
            />
            <Pane display="flex" justifyContent="space-between">
              <Button appearance="minimal" onClick={() => setSignupDialog(false)}>
                Go back to login
              </Button>
              <Button appearance="primary" onClick={signupSubmit}>
                Create my account
              </Button>
            </Pane>
          </Pane> : null}
      </CornerDialog>
    </Pane >
  );
}

export default App.Index;
