import { Alert, Avatar, Badge, BanCircleIcon, Button, Dialog, Heading, ListItem, Pane, TextInputField, TickCircleIcon, toaster, UnorderedList } from "evergreen-ui";
import styles from './Seller.module.scss';
import axios from "axios";
import { useEffect, useState } from "react";
import { Config } from "../config";
import { useStore } from "../utils/Store";
import { motion } from "framer-motion";
import User from "../user/User";
import socketIOClient from "socket.io-client";
import { Api } from "../Api";
import { useParams } from "react-router-dom";
import CountDown from 'count-down-react'

const Product = {
    Index: () => {
        const [store, setStore] = useStore();
        const [commodity, setCommodity] = useState({});
        let { itemId } = useParams();

        useEffect(() => {
            loadCommodity();
        }, []);

        function loadCommodity() {
            axios.get(Api.COMMODITY + itemId).then(res => {
                if (res.data.success) {
                    setCommodity(res.data.data);
                    setStore({ ...store, commodity: res.data })
                }
            })
        }

        const CoundownRenderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed && commodity.active) {
                setCommodity({ ...commodity, active: false });
                loadCommodity();
            }
            return <Pane className={styles.productTimerCounter}>
                {days > 0 ? <Pane>{days}<Pane className={styles.productTimerCounterlabel}>days</Pane></Pane> : ''}
                {hours > 0 ? <Pane>{hours}<Pane className={styles.productTimerCounterlabel}>hours</Pane></Pane> : ''}
                <Pane>{minutes}<Pane className={styles.productTimerCounterlabel}>minutes</Pane></Pane>
                <Pane>{seconds}<Pane className={styles.productTimerCounterlabel}>seconds</Pane></Pane>
            </Pane>;
        }

        const view = commodity && commodity.id ? <Pane className={styles.productWrapper}>
            <Pane className="columns col-gapless">
                <Pane className="column col-5 col-md-12">
                    <Pane className={styles.productDetails}>
                        <Badge color="yellow">PHOTO</Badge>
                        <Heading marginTop={10} size={800}>{commodity.name}</Heading>
                        <Heading marginTop={10} size={500}>Last bid: ${commodity.value}</Heading>
                        <Pane className={styles.productDetailsImg}>
                            <img src={commodity.thumb} />
                        </Pane>
                        {commodity.active ? <Pane className={styles.productTimer}>
                            <Heading marginTop={10} size={500}>Ends in</Heading>
                            <CountDown date={commodity.duration} renderer={CoundownRenderer} />
                        </Pane> : ''}
                    </Pane>
                </Pane>
                <Pane className="column col-7 col-md-12">
                    <Product.Bid commodity={commodity} />
                </Pane>
            </Pane>
        </Pane> : '';
        return view;
    },
    Bid: ({ commodity }) => {
        const [store, setStore] = useStore();
        const [bidControl, setBidControl] = useState(0);
        const [bidItemData, setBiditemData] = useState();
        const [buyerData, setBuyerData] = useState();
        const [bidders, setBids] = useState([]);
        const [bidState, setBidState] = useState({ busy: false, success: false, show: false });
        const [showLogin, setLoginDialog] = useState(false);
        const [bidBusy, setBidBusyState] = useState(false);

        useEffect(() => {
            if (store.commodity && store.commodity.data) {
                setBiditemData(store.commodity.data);
                setBids(store.commodity.history);
                setMinBid(store.commodity.data.value || store.commodity.data.minBid);

                if (!store.commodity.data.active) {
                    setBuyerData(store.commodity.history[0]);
                }
            }
        }, [store.commodity]);

        useEffect(() => {
            if (!commodity.active) {
                return;
            }

            const socket = socketIOClient(Config.ws);
            socket.emit("commodity", commodity.id);

            socket.on("commodity_changes", data => {
                setBiditemData(data.data);

                if (data && data.history.length) {
                    // data.map(newItem => {
                    //     const exists = list.findIndex(item => item.id === newItem.id);

                    //     console.log(list)
                    //     if (exists > -1) {
                    //         if (list[exists].value < newItem.value) {
                    //             list[exists].value = newItem.value;
                    //             setCommList([...list]);
                    //         }
                    //     } else if (exists === -1) {
                    //         setCommList([newItem, ...list]);
                    //     }
                    //     setCommList(list);
                    // });
                    if (data.history.length) {
                        setMinBid(data.history[0].value);
                    }

                    setBids(data.history);
                }

                if (!data.data.active) {
                    socket.disconnect();
                }
            });
        }, []);

        function handleBidForm(event) {
            setBidControl(event.target.value);
        }

        function setMinBid(value) {
            const minBid = +value + (15 * value) / 100;
            setBidControl(minBid);
        }

        function submitBid() {
            if (!store.user || !store.user.id) {
                setLoginDialog(true);
                return;
            }
            setBidBusyState(true);
            const amount = +bidControl;
            setBidState({ success: false, busy: true });
            axios.post(Api.PLACE_BID, {
                "userId": store.user.id,
                "pin": store.authenticator,
                "auctionId": bidItemData.id,
                "amount": amount
            }).then(res => {
                if (res.data.success) {
                    setBidState({ success: true, busy: false, show: true });
                    setStore({ ...store, balance: store.balance - amount })
                    toaster.success('You placed a bid request');
                } else {
                    setBidState({ success: false, busy: false, show: false });
                    toaster.danger('Failed: ' + res.data.code);
                }
                setBidBusyState(false);
            }, err => {
                setBidState({ success: false, busy: false, show: true });
                toaster.danger('Something went wrong. Please try again');
                setBidBusyState(false);
            });
        }

        const bidView =
            commodity && commodity.active ? <Pane className={styles.productPlaceBidWrapper}>
                <Heading size={500}>Place your bid</Heading>
                <Pane className={styles.productPlaceBid}>
                    <Pane marginBottom="-20px">
                        <TextInputField value={bidControl}
                            required={bidState.show && !bidState.success}
                            validationMessage="This field is required"
                            onChange={handleBidForm.bind(this)}
                        />
                    </Pane>
                    <Pane marginTop={7} >
                        <Button isLoading={bidBusy} appearance="primary" onClick={submitBid}>
                            Submit
                        </Button>
                    </Pane>
                </Pane>
            </Pane> :
                <Pane className={styles.productBidEndWrapper}>
                    <Alert
                        intent="success"
                        title="Sold out!"
                        marginBottom={20}
                    />
                    {buyerData ? <Pane className="columns col-gapless">
                        <Pane className={'column col-auto ' + styles.productBuyerDetails}>
                            <Avatar src="https://th.bing.com/th/id/OIP.CQNp8dac1U1rS0F2yIDw4AHaJQ?pid=ImgDet&rs=1"
                                name={buyerData.user.name}
                                size={50}
                            />
                            <Heading size={200} marginTop={10}>{buyerData.user.name}</Heading>
                        </Pane>
                        <Pane className={'column col-auto ' + styles.productBuyerBidDetails}>
                            <Pane>
                                <Heading size={200}>Acquired on</Heading>
                                <Heading size={400}>{commodity.duration}</Heading>
                            </Pane>
                            <Pane>
                                <Heading size={200}>Bought at</Heading>
                                <Heading size={800}>${buyerData.value}</Heading>
                            </Pane>
                        </Pane>
                    </Pane> : ''}
                </Pane>
            ;

        const view =
            // <motion.div
            //     animate={{ marginTop: ['20%', '0%'] }}
            //     transition={{ duration: 0.4 }}>
            <Pane elevation={1} className={styles.productBidContainer}>
                {bidItemData && bidItemData.user && store.user && bidItemData.user.id !== store.user.id ? bidView : ''}
                <Pane>
                    <Heading size={400} marginBottom={20}>Last bids</Heading>
                    <UnorderedList marginX="-20px">
                        {bidders.map((item, key) => <ListItem className={styles.bidderInfo} key={key}>
                            <Pane style={{ display: 'flex', alignItems: 'center' }}><Avatar name={item.user.name} size={40} marginRight={20} /> {item.user.name}</Pane>

                            <Pane>${item.value}</Pane>
                        </ListItem>
                        )}
                    </UnorderedList>
                    {!bidders.length ? <Pane>Sorry, No bids yet!</Pane> : ''}
                </Pane>

                <Product.Login show={showLogin} hide={() => setLoginDialog(false)} />
            </Pane>
        return view;
    },
    Login: ({ show, hide }) => {
        const [isShown, setIsShown] = useState(false);

        useEffect(() => {
            setIsShown(show);
        }, [show]);

        const view = <Pane>
            <Dialog
                isShown={isShown}
                title="Continue with your account"
                onCloseComplete={hide}
                hasFooter={false}
                confirmLabel="Login"
                width={400}
            >
                <User.Login />
            </Dialog>
        </Pane>;
        return view;
    }
}

export default Product;