import axios from "axios";
import { Pane } from "evergreen-ui";
import { useEffect, useState } from "react";
import { Api } from "../Api";
import { useStore } from "../utils/Store";

const Transactions = {
    Index: () => {
        const [store, setStore] = useStore();
        const [transactionsList, setTransactions] = useState([]);
        useEffect(() => {
            axios.get(Api.TRANSACTIONS + store.user.id).then(res => {
                if (res.data.success) {
                    setTransactions(res.data.data);
                }
            })
        }, []);

        const view = <Pane>

        </Pane>;

        return view;
    }
}


export default Transactions;