import { Avatar, Button, Heading, ListItem, Pane, Tab, Tablist, TickCircleIcon, ExchangeIcon, UnorderedList, Text, Badge, HomeIcon, DollarIcon, TextInputField, toaster, WrenchIcon, SwapHorizontalIcon, CleanIcon } from "evergreen-ui";
import { useEffect, useState } from "react";
import styles from './User.module.scss';
import ProgressBar from '../components/Progress'
import { Redirect, Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { motion } from "framer-motion";
import axios from 'axios';
import { Config } from '../config';
import { useStore } from "../utils/Store";
import SellItem from "./Sell";
import { useCookies } from "react-cookie";
import { Api } from "../Api";
import CountDown from "count-down-react";
import Transactions from "./Transactions";

const User = {
    Index: () => {
        const routerBase = useRouteMatch();
        const [store] = useStore();

        useEffect(() => {
            // if (!store.user && !store.user.id) {

            // }
        }, [store.user]);

        const view = <Pane className={styles.userHomeContainer + ' columns'}>
            <Pane className="column col-3 col-lg-4 col-md-12">
                <User.ProfilePanel />
            </Pane>
            <Pane className="column col-9 col-lg-8 col-md-12">
                <Switch>
                    <Route exact={true} path="/user">
                        <User.UserLanding />
                    </Route>
                    <Route exact={true} path="/user/dashboard">
                        <User.UserLanding />
                    </Route>
                    <Route exact={true} path={routerBase.path + '/sell'}>
                        <SellItem.Index />
                    </Route>
                    <Route exact={true} path="/user/transactions">
                        <Transactions.Index />
                    </Route>
                </Switch>
            </Pane>
        </Pane>;
        // return view;
        return store.user && store.user.id ? view : <Redirect to="/" />;

    },
    Login: () => {
        const [cookies, setCookie] = useCookies(['_user']);
        const [store, setStore] = useStore();
        const [signInForm, setSignInForm] = useState({ email: '', password: '' });

        function handleSigninForm(event) {
            const d = { ...signInForm };
            d[event.target.name] = event.target.value;
            setSignInForm(d)
        }

        function loginSubmit() {

            axios.post(Api.LOGIN, { "email": signInForm.email, "password": signInForm.password }).then(res => {
                if (res.data.success) {
                    setCookie('_user', res.data.data.id);
                    setStore({ ...store, balance: res.data.data.balance, user: res.data.data, authenticator: signInForm.password });
                    toaster.success('Login Success', {
                        duration: 1
                    });
                    // getUserUpdates(res.data.data.id, signInForm.password);
                } else {
                    toaster.danger('Login failed');
                }
            }, err => {
                toaster.danger('Login failed');
            });
        }

        function setSignupDialog() {

        }

        const view = <Pane>
            <TextInputField
                label="Email" name="email" type="email" value={signInForm.email} onChange={handleSigninForm.bind(this)}
            />
            <TextInputField
                label="Password" name="password"
                type="password" value={signInForm.password} onChange={handleSigninForm.bind(this)}
            />
            <Pane display="flex" justifyContent="space-between">
                <Link to="/user/signup">
                    <Button appearance="minimal" onClick={() => setSignupDialog()}>
                        Create a new account.
                    </Button>
                </Link>
                <Button appearance="primary" onClick={loginSubmit}>
                    Sign In
                </Button>
            </Pane>
        </Pane>;
        return view;
    },
    ProfilePanel: () => {
        let location = useLocation();
        const [activePage, setPage] = useState();
        const [store] = useStore();

        useEffect(() => {
            if (location) {
                const path = location.pathname.split('/').reverse();
                setPage(path[0]);
            }
        }, [location]);

        console.log(location)
        const sideMenuList = [{
            id: 'dashboard',
            name: 'Dashboard',
            icon: HomeIcon
        }, {
            id: 'wallet',
            name: 'Wallet',
            icon: DollarIcon
        }, {
            id: 'transactions',
            name: 'Transactions',
            icon: SwapHorizontalIcon
        }, {
            id: 'settings',
            name: 'Settings',
            icon: WrenchIcon
        }];

        const view =
            store.user && store.user.id ? <motion.div
                animate={{ marginLeft: ['-100%', '0%'] }}
                transition={{ duration: 0.4 }}
                className={styles.profilePanel}>
                <Pane className="columns" paddingX={30}>
                    <Pane className="column col-12  col-md-3 col-xs-12">
                        <Pane className="columns">
                            <Pane className="column col-auto col-mx-auto">
                                <Avatar
                                    src="https://media.istockphoto.com/vectors/default-avatar-man-to-social-user-vector-id860264876?k=6&m=860264876&s=170667a&w=0&h=2YApZVjoA2w1PBJonLA-PUfgbIHltDXqBZWlJI2Zo7M="
                                    name={store.user.name}
                                    size={100}
                                />
                            </Pane>
                        </Pane>
                    </Pane>
                    <Pane className="column col-12 col-md-auto px-2 col-xs-12">
                        <Pane className="columns">
                            <Pane className="column col-auto col-mx-auto">
                                <Heading size={800} className={styles.profilePanelAvatarLabel}>{store.user.name}</Heading>
                                <UnorderedList>
                                    <ListItem icon={TickCircleIcon} iconColor="success" >
                                        @{store.user.username}
                                    </ListItem>
                                </UnorderedList>
                            </Pane>
                        </Pane>
                    </Pane>
                    <Pane className="column col-12 col-md-auto col-xs-12 col-ml-auto">
                        <Pane className="columns">
                            <Pane className="column col-auto col-mx-auto">
                                <Button marginY="20px" intent="success">
                                    EDIT PROFILE
                                </Button>
                            </Pane>
                        </Pane>
                    </Pane>
                    <Pane className={styles.profileSideMenu + ' hide-md'}>
                        {
                            sideMenuList.map(item => <Link key={item.id} to={'/user/' + item.id}>
                                <Pane key={item.id} className={'profileSideMenuItem ' + (activePage === item.id ? ' active' : '')}>
                                    <item.icon className="icon" />   {item.name}
                                </Pane>
                            </Link>)
                        }
                    </Pane>
                </Pane>

                {store && store.user && store.user.seller ? <Pane className={styles.createNewAuctionContainer}>
                    <Link to="/user/sell">
                        <Pane className={styles.createNewAuction} elevation={2}>
                            <Pane className={styles.createNewAuctionIcon}><CleanIcon /></Pane>
                            <Pane>
                                <Heading className={styles.createNewAuctionText} size={600}>Sell</Heading>
                                <Heading className={styles.createNewAuctionTextSub} size={200}>Exclusive fan contents</Heading>
                            </Pane>
                        </Pane>
                    </Link>
                </Pane> : ''}
            </motion.div > : ''
        return view;
    },
    UserLanding: () => {
        const [currentTab, setTabIndex] = useState(0);
        const [store, setStore] = useStore();

        const tabs = ["Overview", "Sold Items"]

        const view = <Pane className={styles.userContentWrapper}>
            <Heading size={500} className={styles.profilePanelAvatarLabel}>{store.user.name}</Heading>
            <Pane className="mt-2 pt-2">
                <Pane className={styles.profileTabSet}>
                    <Tablist>
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                id={tab}
                                onSelect={() => setTabIndex(index)}
                                isSelected={index === currentTab}
                                aria-controls={`panel-${tab}`}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tablist>
                </Pane>
                <Pane className="columns py-2 my-2">
                    <Pane className="column col-4 col-md-8 col-lg-7 col-xl-5 col-sm-12">
                        <Pane
                            elevation={2}
                            height={140}
                            className={styles.cardBalance}
                        >
                            <Text color="gray100" className="pb-2">Balance</Text>
                            <Heading size={800} color="tint2">${store.balance}</Heading>
                        </Pane>
                    </Pane>
                </Pane>
                <Pane>
                    <User.ActionRequired />
                    <User.ActiveList />
                </Pane>
            </Pane>
        </Pane>;

        return view;
    },
    ActionRequired: () => {
        const view = <Pane>

        </Pane>
        return view;
    },
    ActiveList: () => {
        const [store] = useStore();
        const [activeList, setActiveList] = useState([]);

        useEffect(() => {
            axios.get(Config.api + `/seller/${store.user.id}` + Api.SELLER_ACTIVE_COMMODITY).then(res => {
                if (res.data.success) {
                    setActiveList(res.data.data);
                }
            });
        }, []);

        const CoundownRenderer = ({ days, hours, minutes, seconds }) => {
            return <>
                {days > 0 ? <span>{days} days </span> : ''}
                {hours > 0 ? <span>{hours} hours </span> : ''}
                <span>{minutes} minutes </span>
                <span>{seconds} seconds </span>
            </>;
        }

        const view = <Pane>
            <Heading size={500} className={styles.profilePanelAvatarLabel}><ExchangeIcon marginRight={10} /> Active Commodities</Heading>
            <Pane>
                {activeList.map((item, i) => <Pane key={i} className={styles.commodityListItem} background="gray50" elevation={1} minHeight={120}>
                    <Pane className="columns" style={{ alignItems: 'center' }}>
                        <Pane className="column col-2 pr-2 col-md-2 col-xs-3">
                            <img className={styles.commodityThumb} src={item.thumb} />
                        </Pane>
                        <Pane className="column col-8 col-sm-9 pb-2 pl-2">
                            <Heading color="gray900" className="ml-2" size={600}>
                                {item.name}
                            </Heading>
                            <Badge color="yellow" className="mx-2">PHOTO</Badge>  <Badge color="neutral">35 bids</Badge>
                        </Pane>
                        <Pane className="column col-2 col-sm-8 text-right">
                            <Heading color="gray100" size={200}>
                                Current Price
                            </Heading>
                            <Heading color="gray900" size={700}>
                                ${item.value}
                            </Heading>
                        </Pane>
                        {/* </Pane>
                <Pane className="columns pt-2" style={{ alignItems: 'center' }}> */}
                        <Pane className="column col-2 col-sm-4 px-2 py-2">
                            <Heading color="gray100" size={200}>
                                Published on
                            </Heading>
                            <Heading color="gray900" size={400}>
                                Jun 20,2021
                            </Heading>
                        </Pane>
                        <Pane className="column col-7 col-sm-12 py-2">
                            <ProgressBar.Line />
                            <Heading color="101840" size={200} className="pt-2">
                                Ends in  <CountDown date={item.duration} renderer={CoundownRenderer} />
                            </Heading>
                        </Pane>
                        <Pane className="column col-2 col-mx-auto col-sm-12 text-center">
                            <Link to={'/profile/' + item.user.username + '/item/' + item.id}><Button intent="success" width="100%">VIEW</Button></Link>
                        </Pane>
                    </Pane>
                </Pane>
                )}
            </Pane>
        </Pane>
        return view;
    }

}

export default User;