import { Pane, Heading, Badge, Avatar, Popover, Position, Button, Paragraph } from "evergreen-ui"; import axios from "axios";
import { Config } from "../config";
import Masonry from "masonry-layout";
import styles from './Home.module.scss';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Api } from "../Api";
import { useState } from "react";

const Home = {
    Index: () => {

        // const body = <Pane padding={50}>
        //     <Heading size={800} marginBottom={20}><ChangesIcon marginRight={10} /> Trending</Heading>
        //     <Pane>
        //         <Table>
        //             <Table.Head>
        //                 <Table.SearchHeaderCell />
        //                 <Table.TextHeaderCell size={100}>Last Bid</Table.TextHeaderCell>
        //                 <Table.TextHeaderCell ></Table.TextHeaderCell>
        //             </Table.Head>
        //             <Table.Body height={240}>
        //                 {commodityList.map((commodity) => (
        //                     <Table.Row isHighlighted={true} key={commodity.id} isSelectable onSelect={() => blaceBid(commodity)}>
        //                         <Table.TextCell>
        //                             <Pane display="flex" alignItems="center">
        //                                 <Avatar name={commodity.user.name} size={40} marginRight={20} />
        //                                 <Heading size={400} textTransform="capitalize">{commodity.name}</Heading>
        //                             </Pane>
        //                         </Table.TextCell>
        //                         <Table.TextCell> <Badge color="green">${commodity.value}</Badge></Table.TextCell>
        //                         <Table.TextCell isNumber><StatusIndicator color="warning">New Bid!</StatusIndicator></Table.TextCell>
        //                     </Table.Row>
        //                 ))}
        //             </Table.Body>
        //         </Table>
        //         {commodityList.length === 0 ? 'No lisiting yet' : ''}
        //     </Pane>

        //     <CornerDialog
        //         title="Place your bid"
        //         isShown={showBidDialog}
        //         onConfirm={() => submitBid()}
        //         confirmLabel="Place Bid"
        //         cancelLabel="Cancel"
        //     >
        //         {bidItemData && bidItemData.id ? <Pane display="flex" justifyContent="space-between" alignItems="center">
        //             <UnorderedList>
        //                 <ListItem icon={ExchangeIcon} iconColor="success">
        //                     <Heading textTransform="capitalize">{bidItemData.name}</Heading>
        //                 </ListItem>
        //             </UnorderedList>
        //             <Pane>
        //                 <i>Current value:</i>  <Badge color="neutral"><strong>${bidItemData.value}</strong></Badge>
        //             </Pane>
        //         </Pane> : null}
        //         <TextInputField marginTop={20}
        //             label="Your bid amount" value={bidControl}
        //             required={bidState.show && !bidState.success}
        //             validationMessage="This field is required"
        //             onChange={handleBidForm.bind(this)}
        //         />
        //     </CornerDialog>
        // </Pane>

        const view = <Pane className="page-container columns">
            <Pane className="page-container-body column col-12 col-mx-auto">
                <Home.Trending />
                <Home.Testimonial />
            </Pane>
        </Pane>;

        return view;
    },
    Trending: () => {
        const [featuredColA, setFeaturedColA] = useState([]);
        const [featuredColB, setFeaturedColB] = useState([]);
        const [featuredColC, setFeaturedColC] = useState([]);

        useEffect(() => {

            axios.get(Api.FEATURED).then(res => {
                if (res.data.success) {
                    setFeaturedColA(res.data.data);
                }
            })


        }, []);


        useEffect(() => {
            setTimeout(() => {
                new Masonry('#grid-masonry-1', {
                    itemSelector: '.item',
                    columnWidth: 1
                })
                new Masonry('#grid-masonry-2', {
                    itemSelector: '.item',
                    columnWidth: 1
                })
                new Masonry('#grid-masonry-3', {
                    itemSelector: '.item',
                    columnWidth: 1
                });
            }, 500);
        }, [featuredColA, featuredColB, featuredColC]);

        const view = <Pane className={styles.trendingLayout}>
            <Pane className="columns">
                <Pane className="column col-auto col-mx-auto">
                    <Heading className="text-center underline" size={900}>Now Trending</Heading>
                </Pane>
            </Pane>
            <Pane className={styles.homeContainer}>
                <Pane className="columns col-gapless">
                    <Pane id="grid-masonry-1" className="grid-masonry column col-4 col-mx-auto col-lg-12">
                        {featuredColA.map(item => <div className="item" key={item.id}>
                            <Popover
                                content={
                                    <Pane
                                        width={240}
                                        height={240}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        flexDirection="column"
                                    >
                                        <Pane>
                                            <Heading size={800}>{item.name}</Heading>
                                            <Paragraph size={300} marginBottom={12}>{item.description}</Paragraph>
                                        </Pane>
                                        <Link to={`/profile/${item.user.username}/item/${item.id}`}>
                                            <Button appearance="primary">
                                                View
                                            </Button>
                                        </Link>
                                    </Pane>
                                }
                                position={Position.RIGHT}
                            >
                                <Pane className="grid-item-content" elevation={2}>
                                    <img src={item.thumb} />

                                    <Pane className="grid-item-content-details">
                                        <Heading color="white">{item.user.name}</Heading>
                                        <Badge color="yellow">PHOTO</Badge>
                                    </Pane>

                                </Pane>
                            </Popover>
                        </div>
                        )}
                    </Pane>
                    <Pane id="grid-masonry-2" className="grid-masonry column col-4 col-mx-auto col-lg-12">
                        {featuredColB.map(item => <div className="item" key={item.id}>
                            <Popover
                                content={
                                    <Pane
                                        width={240}
                                        height={240}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        flexDirection="column"
                                    >
                                        <Pane>
                                            <Heading size={800}>{item.name}</Heading>
                                            <Paragraph size={300} marginBottom={12}>{item.description}</Paragraph>
                                        </Pane>
                                        <Link to={`/profile/${item.user.username}/item/${item.id}`}>
                                            <Button appearance="primary">
                                                View
                                            </Button>
                                        </Link>
                                    </Pane>
                                }
                                position={Position.RIGHT}
                            >
                                <Pane className="grid-item-content" elevation={2}>
                                    <img src={item.thumb} />

                                    <Pane className="grid-item-content-details">
                                        <Heading color="white">{item.user.name}</Heading>
                                        <Badge color="yellow">PHOTO</Badge>
                                    </Pane>

                                </Pane>
                            </Popover>
                        </div>
                        )}
                    </Pane>
                    <Pane id="grid-masonry-3" className="grid-masonry column col-4 col-mx-auto col-lg-12">
                        {featuredColC.map(item => <div className="item" key={item.id}>
                            <Popover
                                content={
                                    <Pane
                                        width={240}
                                        height={240}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        flexDirection="column"
                                    >
                                        <Pane>
                                            <Heading size={800}>{item.name}</Heading>
                                            <Paragraph size={300} marginBottom={12}>{item.description}</Paragraph>
                                        </Pane>
                                        <Link to={`/profile/${item.user.username}/item/${item.id}`}>
                                            <Button appearance="primary">
                                                View
                                            </Button>
                                        </Link>
                                    </Pane>
                                }
                                position={Position.RIGHT}
                            >
                                <Pane className="grid-item-content" elevation={2}>
                                    <img src={item.thumb} />

                                    <Pane className="grid-item-content-details">
                                        <Heading color="white">{item.user.name}</Heading>
                                        <Badge color="yellow">PHOTO</Badge>
                                    </Pane>

                                </Pane>
                            </Popover>
                        </div>
                        )}
                    </Pane>
                </Pane>
            </Pane>




        </Pane>;
        return view;
    },
    Testimonial: () => {
        const view = <Pane className={styles.trendingLayout}>
            <Pane className="columns" >
                <Pane className="column col-6 col-mx-auto text-center">
                    <Heading size={900}>"Thanks to Amuse Bouche, we're able to know what's going on with our revenue and customers, in real-time. Absolutely invaluable and miles ahead of doing it in spreadsheets or coding your own."</Heading>
                </Pane>
            </Pane>
            <Pane className="columns" marginTop="40px">
                <Pane className="column col-6 col-mx-auto text-center">
                    <Link to="/profile/itsbitch"><Avatar
                        src="https://instagram.fblr1-6.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/c0.180.1440.1440a/s640x640/195452697_5701589433215460_4648201227133025748_n.jpg?tp=1&_nc_ht=instagram.fblr1-6.fna.fbcdn.net&_nc_cat=100&_nc_ohc=YT26ELhK3xQAX9oGhG-&edm=ABfd0MgBAAAA&ccb=7-4&oh=517a9047ed577229b83d7b0dc0700263&oe=60CD1351&_nc_sid=7bff83"
                        name="Shanaya Joshy"
                        size={60}
                    />
                    </Link>
                    <Heading>Shanaya Joshy</Heading>
                    <Heading color="red300" size={400}>
                        <a target="_BLANK" href="https://www.instagram.com/itsbitch/">@itsbitch</a>
                    </Heading>
                </Pane>
            </Pane>
        </Pane>;

        return view;
    }
};


export default Home;